

    import {Component, Vue} from "vue-property-decorator";
    import RouteDialog from "../components/RouteDialog.vue";
    import {ChargePoint, RouteDialogConfirmEvent} from "@/domain";
    @Component({
        components: {RouteDialog}
    })
    export default class DeleteChargePointDialog extends Vue {
        loading: boolean = true;

        async onConfirm(evt: RouteDialogConfirmEvent) {
            try {
                await this.$store.dispatch("chargePoints/deleteOne", this.$route.params.id)
                    .then(() => this.$store.dispatch("chargePoints/fetchAll"));
                evt.done();
            } catch(e) {
                console.log("An error occured while deleting", e);
                evt.cancel();
            }
        }

        async mounted() {
            await this.$store.dispatch("chargePoints/fetchOne", this.$route.params.id);
            this.loading = false;
        }
    }
